/** @module PoissonDiskSamplingGrid */

/**
 * POISSON DISK SAMPLING GRID
 * ----------------------------------------
 * Returns a promise of a Poisson disk grid.
 * TODO: Make this use a Box3 for the shape parameter.
 */

import { defaults } from 'lodash-es'
import PoissonDiskSampling from 'poisson-disk-sampling'

export default class PDSGrid {
  /**
   * @param { Object } shape
   * @param { number } minDistance
   * @param { number } options
   * @returns { Object }
   */
  constructor (shape, minDistance, options) {
    this.opts = defaults(options, {
      maxDistance: minDistance * 2,
      maxTries: 30,
      rng: Math.random,
      cache: true
    })

    this.minDistance = minDistance
    this.shape = shape

    return this.createGrid()
  }

  createGrid () {
    return new Promise((resolve, reject) => {
      if (!this.shape) {
        reject(new Error('Must provide a shape for the grid.'))
      }

      if (!this.minDistance) {
        reject(new Error('Must provide a minimum distance between grid points.'))
      }

      if (!this.shape.isVector3) {
        reject(new Error('Shape parameter must be instance of THREE.Vector3.'))
      }

      const ssPds = JSON.parse(window.sessionStorage.getItem('pds'))

      if ((ssPds !== null) &&
          (this.opts.cache !== false)
      ) {
        resolve(ssPds)
      } else {
        const pds = new PoissonDiskSampling({
          shape: this.shape.toArray(),
          minDistance: this.minDistance,
          maxDistance: this.opts.maxDistance,
          tries: this.opts.maxTries
          // distanceFunction: this.opts.rng
        }).fill()

        window.sessionStorage.setItem('pds', JSON.stringify(pds))

        resolve(pds)
      }
    })
  }
}
