import { defaults, isFunction, isEmpty, filter } from 'lodash-es'
import * as Three from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as Common from './Common'
import FixedFOVBackdropCamera from './FixedFOVBackdropCamera'
import AxisIndicator from './ThreeAxisIndicator'
import PDSGrid from './PoissonDiskSamplingGrid'

export default class ThreeBackdrop {
  constructor (el, options) {
    this.opts = defaults(options, {
      colors: {
        sky: '#ffffff',
        ground: '#000000',
        background: null
      },
      shapes: [
        'http://localhost:8080/square.glb'
      ],
      filter: [],
      scale: 1,
      cache: true,
      speed: 1000,
      rotation: true,
      scroll: {
        top: 0,
        left: 0
      }
    })

    // Store the element
    this.$el = el

    // Create a renderer...
    this.renderer = new Three.WebGLRenderer({
      alpha: true,
      antialias: true,
      logarithmicDepthBuffer: true
    })

    // Set the renderer's clear color (for transparent BG)
    this.renderer.setClearColor(0xffffff, 0)

    this.rect = this.$el.getBoundingClientRect()
    this.$el.appendChild(this.renderer.domElement)

    // this.$el.style.background = this.opts.colors.background

    // Make an axis helper to use if needed...
    this.axisHelper = new AxisIndicator(500, 20, {
      colors: {
        origin: '#F2CE75',
        x: '#FC5A3C',
        y: '#0F9978',
        z: '#0F6899'
      }
    })

    // Make a grid helper to use if needed...
    this.gridHelper = new Three.GridHelper(10000, 100, 'red', 'silver')

    // Create a camera...
    this.camera = this.createCamera()

    // Make a scene!
    this.scene = new Three.Scene()
    this.scene.background = this.opts.colors.background

    // Create a GLTF loader to use if needed...
    this.gltfLoader = new GLTFLoader()

    // Create a base material to use...
    this.baseMaterial = new Three.MeshPhongMaterial({
      color: '#e5e5fd'
    })

    // Create some lighting for the scene
    this.lighting = new Three.HemisphereLight(
      this.opts.colors.ground,
      this.opts.colors.sky,
      1
    )

    // Ready to go!
    this.mounted()
  }

  createCamera () {
    const c = new FixedFOVBackdropCamera(
      this.rect.width,
      this.rect.height,
      30,
      0.1,
      10000
    )

    c.onInit = function (env) {
      Common.centerObjectAtScroll(this)
      this.position.z = this.getDistance()
      this.updateProjectionMatrix()
    }

    c.onDraw = function (env) {
      Common.centerObjectAtScroll(this)
      this.updateProjectionMatrix()
    }

    return c
  }

  onResize (event) {
    this.rect = this.$el.getBoundingClientRect()
    this.updateSize()
    this.onDraw()
  }

  onScroll (event) {
    this.scroll = {
      top: window.scrollY,
      left: window.scrollX
    }
    this.onDraw()
  }

  onInit (event) {
    this.scene.traverseVisible(o => {
      if (isFunction(o.onInit)) o.onInit(this)
    })
    this.updateSize()
    this.onDraw()
  }

  onDraw (event) {
    // Update simulation:
    this.scene.traverseVisible((o) => {
      if (isFunction(o.onDraw)) o.onDraw(this)
    })
    this.renderer.render(this.scene, this.camera)
  }

  onFocus (event) {
    // onFocus stuff here
  }

  getRect () {
    return this.$el.getBoundingClientRect()
  }

  updateSize () {
    this.renderer.setSize(this.rect.width, this.rect.height)
    this.camera.aspect = this.rect.width / this.rect.height
    this.camera.updateProjectionMatrix()
    this.onDraw()
  }

  filterMeshes (meshes, filterList) {
    let filteredMeshes = []
    return new Promise((resolve, reject) => {
      // if filtering is enabled
      if (!isEmpty(this.opts.filter)) {
        filteredMeshes = filter(meshes, o => {
          return filterList.includes(o.name)
        })
        resolve(filteredMeshes)
      } else resolve(meshes)
    })
  }

  // Returns a promise for an array of shapes
  // Uses loadShapesSingleFile if provided a string,
  // otherwise it will use loadShapesMultiFile
  loadShapes () {
    let meshes = []
    return new Promise((resolve, reject) => {
      if (typeof this.opts.shapes === 'string') {
        meshes = this.loadShapesSingleFile()
      } else {
        meshes = this.loadShapesMultiFile()
      }

      resolve(meshes)
    })
  }

  loadShapesSingleFile () {
    const file = this.opts.shapes
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(file, gltf => {
        resolve(gltf.scene.children)
      })
    })
  }

  loadShapesMultiFile () {
    // Promises multiple shapes
    return Promise.all(this.opts.shapes.map(file => {
      // Promises a single shape
      // TODO: check if this is needed (does gltfLoader use promises?)
      return new Promise((resolve, reject) => {
        this.gltfLoader.load(file, gltf => {
          resolve(gltf.scene.children[0])
        })
      })
    }))
  }

  generateGrid (gridShape) {
    const pdsGrid = new PDSGrid(gridShape, 400, {
      maxDistance: 500,
      cache: this.opts.cache
    })
    return pdsGrid
  }

  mounted () {
    this.updateSize()

    this.camera.aspect = this.rect.width / this.rect.height
    this.camera.updateProjectionMatrix()

    // this.scene.add(this.gridHelper)
    // this.scene.add(this.axisHelper)
    this.scene.add(this.lighting)
    this.scene.add(this.camera)

    const fieldPadding = {
      x: Common.getDisplayWidth(),
      y: Common.getDisplayHeight()
    }

    // Create a Vector3 to describe the extents of
    // the grid volume. Defaults to 1000 depth.
    // TODO: make the depth configurable?
    const gridShape = new Three.Vector3(
      Common.getDisplayWidth() + (2 * fieldPadding.x),
      Common.getDocumentHeight() + (2 * fieldPadding.y),
      1000
    )

    // Create a promise for a field of shapes that will get fulfilled
    // once the shapes have been loaded and distributed according to
    // a grid that we will also generate here:

    Promise.all([this.loadShapes(), this.generateGrid(gridShape)]).then(components => {
      const meshes = components[0]
      const grid = components[1]
      const field = new Three.Object3D()

      this.filterMeshes(meshes, this.opts.filter).then(meshes => {
        grid.forEach((point, i) => {
          // Clone a random mesh OR the only mesh
          const mesh = meshes.length > 1 ? meshes[Common.randomIntBetween(0, meshes.length - 1)].clone() : meshes[0].clone()

          // Put the shape in a new group
          const group = new Three.Group()

          // Rotate the group a bit to give it some randomness
          const r = Common.randomWithNegatives()
          group.rotation.set(r * Math.PI, r * Math.PI, r * Math.PI)
          group.position.set(point[0], point[1], point[2])

          // Give the group a name & set scale
          group.name = 'hmShape-' + i
          mesh.scale.set(this.opts.scale, this.opts.scale, this.opts.scale)

          // mesh.material = this.baseMaterial

          // Attach some animation behavior to the group
          // so that it changes rotation a bit as the user
          // scrolls the page up and down.
          if (this.opts.rotation) {
            const s = Common.randomWithNegatives()
            const v = (1 - this.opts.speed) * 1000
            mesh.onDraw = function (env) {
              const r = s * (window.scrollY / v)
              this.rotation.set(r * Math.PI, r * Math.PI, r * Math.PI)
            }
          }

          // Add the mesh to the group and add the group to the field
          group.add(mesh)
          field.add(group)

          field.position.set(
            -1 * fieldPadding.x,
            -1 * fieldPadding.y,
            0
          )

          // Add the field to the scene
          this.scene.add(field)

          // Draw calls
          this.onDraw()
        })
      })
    })

    window.addEventListener('resize', this.onResize.bind(this))
    window.addEventListener('scroll', this.onScroll.bind(this))
    window.addEventListener('focus', this.onFocus.bind(this))

    // Draw calls
    this.onDraw()
  }
};
