/** @module Common */
/**
 * iso.js
 * ----------------------------------------
 * Common functions & utilities.
 */

import { isSet } from 'lodash-es'
import * as Three from 'three'

/**
 * CLOSEST TO
 * ----------------------------------------
 * Returns the element from {options} that
 * is closest to {goal}.
 */
export function closestTo (options, goal) {
  return options.reduce(function (prev, curr) {
    return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)
  })
}

/**
 * DEGREES TO RADIANS
 * ----------------------------------------
 * Converts degress to radians.
 *
 * @param { number } degrees Degrees to convert to radians
 * @returns { number }
 */
export function degreesToRadians (degrees) {
  return degrees * Math.PI / 180
}

/**
 * GET ORIENTATION
 * ----------------------------------------
 * Detects device orientation. Falsey if
 * device does not support orientation.
 *
 * @returns { boolean }
 */
export function getOrientation () {
  if (typeof window.orientation !== 'undefined') {
    return Math.abs(window.orientation) - 90 === 0 ? 'landscape' : 'portrait'
  } else return false
}

/**
 * SCREEN SIZE
 * ----------------------------------------
 * Detects available screen size.
 *
 * @returns { Object }
 */
export function screenSize () {
  return {
    width: Math.max(window.screen.width, window.screen.availWidth),
    height: Math.max(window.screen.height, window.screen.availHeight)
  }
}

/**
 * LARGEST AVAILABLE SCREEN DIMENSION
 * ----------------------------------------
 * Detects largest available screen size.
 * Returns max of width and height, because
 * user may be on mobile and could reorient.
 *
 * @returns { number }
 */
export function maxScreenDimension () {
  const s = screenSize()
  return Math.max(s.width, s.height)
}

/**
 * RANDOM WITH NEGATIVES
 * ----------------------------------------
 * Works like Math.random(), but also will
 * output negative values, from -1 to 1.
 *
 * @returns { number }
 */
export function randomWithNegatives () {
  return Math.random() * 2 - 1
}

/**
 * RANDOM INT BETWEEN
 * ----------------------------------------
 * Outputs a random integer between
 * two other given integers.
 *
 * @returns { number }
 */
export function randomIntBetween (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * SCROLL MAX
 * ----------------------------------------
 * Determines the maximum size available
 * for scrolling on the page.
 *
 * @returns { number }
 */
export function scrollMax () {
  const b = document.body
  const d = document.documentElement
  const s = 'scroll'
  const o = 'offset'
  const c = 'client'
  const h = 'Height'
  const w = 'Width'

  return {
    x: Math.max(b[s + w], b[o + w], d[c + w], d[s + w], d[o + w]),
    y: Math.max(b[s + h], b[o + h], d[c + h], d[s + h], d[o + h])
  }
}

/**
 * VIEWPORT DIMENSIONS
 * ----------------------------------------
 * Returns the dimensions of the viewport.
 *
 * @returns { Object }
 */
export function getViewportDimensions () {
  const html = document.documentElement

  return {
    x: Math.max(
      html.clientWidth,
      window.innerWidth || 0
    ),
    y: Math.max(
      html.clientHeight,
      window.innerHeight || 0
    )
  }
}

/**
 * VIEWPORT WIDTH
 * ----------------------------------------
 * Returns the width of the viewport.
 *
 * @returns { number }
 */
export function getViewportWidth () {
  return getViewportDimensions().x
}

/**
 * VIEWPORT HEIGHT
 * ----------------------------------------
 * Returns the height of the viewport.
 *
 * @returns { number }
 */
export function getViewportHeight () {
  return getViewportDimensions().y
}

/**
 * DISPLAY DIMENSIONS
 * ----------------------------------------
 * Returns the dimensions of the display.
 *
 * @returns { Object }
 */
export function getDisplayDimensions () {
  return {
    x: window.screen.width,
    y: window.screen.height
  }
}

/**
 * DISPLAY WIDTH
 * ----------------------------------------
 * Returns the width of the display.
 *
 * @returns { number }
 */
export function getDisplayWidth () {
  return getDisplayDimensions().x
}

/**
 * DISPLAY HEIGHT
 * ----------------------------------------
 * Returns the height of the display.
 *
 * @returns { number }
 */
export function getDisplayHeight () {
  return getDisplayDimensions().y
}

/**
 * DOCUMENT DIMENSIONS
 * ----------------------------------------
 * Returns the dimensions of the document.
 *
 * @return { Object }
 */
export function getDocumentDimensions () {
  const body = document.body
  const html = document.documentElement

  return {
    x: Math.max(
      body.scrollWidth,
      html.scrollWidth,
      body.offsetWidth,
      html.offsetWidth,
      body.clientWidth,
      html.clientWidth
    ),
    y: Math.max(
      body.scrollHeight,
      html.scrollHeight,
      body.offsetHeight,
      html.offsetHeight,
      body.clientHeight,
      html.clientHeight
    )
  }
}

/**
 * DOCUMENT WIDTH
 * ----------------------------------------
 * Returns the width of the document.
 *
 * @returns { number }
 */
export function getDocumentWidth () {
  return getDocumentDimensions().x
}

/**
 * DOCUMENT HEIGHT
 * ----------------------------------------
 * Returns the height of the document.
 *
 * @returns { number }
 */
export function getDocumentHeight () {
  return getDocumentDimensions().y
}

/**
 * GET SCROLL PERCENT
 * ----------------------------------------
 * Returns the percentage x,y (in decimal
 * form) that the user has scrolled.
 */
// export function getScrollPercent () {
//   let h = document.documentElement
//   let b = document.body
//   let w = window
//   let st = 'scrollTop'
//   let sh = 'scrollHeight'
//   let sl = 'scrollLeft'
//   let sw = 'scrollWidth'
//   let yo = 'pageYOffset'
//   let xo = 'pageXOffset'

//   return {
//     x: $(window).scrollLeft() / (scrollMax().x - h.clientWidth),
//     y: $(window).scrollTop() / (scrollMax().y - h.clientHeight)
//   }
// }

/**
 * CENTER OBJECT AT SCROLL
 * ----------------------------------------
 * Centers an element at the center of the
 * window, based on the scroll position.
 *
 * @param { Object } o - The object to center
 * @param { function } cb
 * @returns { Object }
 */
export function centerObjectAtScroll (o, cb) {
  const h = document.documentElement
  const b = document.body
  const w = window
  const st = 'scrollTop'
  const sl = 'scrollLeft'
  const iw = 'innerWidth'
  const ih = 'innerHeight'

  // Set the object's position:
  // o.position.x = (w[iw] / 2) + (h[sl] || b[sl])
  // o.position.y = (w[ih] / 2) - (h[st] || b[st])

  o.position.x = (w[iw] / 2) + (h[sl] || b[sl])
  o.position.y = (w[ih] / 2) + (h[st] || b[st])

  // Call the callback:
  if (isSet(cb)) { cb(o) }

  // Return the object:
  return o
}

/**
 * UNIFORM RANDOM NORMAL
 * ----------------------------------------
 * Creates a unified random normal,
 * expressed as a Vector3 object.
 *
 * @returns { Object }
 */
export function uniformRandomNormal () {
  let vector = null
  do {
    vector = new Three.Vector3(
      randomWithNegatives(),
      randomWithNegatives(),
      randomWithNegatives()
    )
  } while (vector.length() < 0.001)
  return vector.normalize()
}

/**
 * EXP RANDOM VALUE
 * ----------------------------------------
 * Outputs an exponentially random
 * value given an input.
 *
 * @param { number } l
 */
export function exponentiallyRandomValue (l) {
  return l * Math.exp(-l * randomWithNegatives())
}
